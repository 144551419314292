<template>
  <div class="ccontiner">
    <div class="callcon">
      <div class="calendarleft">

        <el-calendar class="ccalender" v-model="currentdate">
          <!-- 这里使用的是 2.5 slot 语法，对于新项目请使用 2.6 slot 语法-->
          <template slot="dateCell" slot-scope="{date, data}">
            <div class="dayarea" @click="changedata(data.day)">

              <p :class="[data.isSelected ? 'is-selected' : '', 'day']">
                {{ data.day.split('-').slice(1).join('-') }} {{ data.isSelected ? '' : '' }}
              <div class="ccon">
                <div v-for="item in markList" :key="item.showDate">
                  <div class="cconbtn" v-if="item.showDate === data.day">
                    <div v-if="item.guapai > 0" class="orange"></div>
                    <div v-if="item.chengjiao > 0" class="blue"></div>
                  </div>
                </div>
              </div>
              </p>
            </div>
          </template>

        </el-calendar>
        <div class="miaoshu">
          <div class="orange"></div>
          <div> 报名截止日</div>
          <div class="blue"></div>
          <div> 竞拍日</div>
        </div>

      </div>
      <div class="list">
        <div class="bmjzr listitem">
          <div class="listtitle">
            <p>报名截止日</p>
          </div>
          <div class="listcon">
            <div v-if="listByDate.bmjzr.length === 0">
              当前日无数据
            </div>
            <div v-else class="conitem" v-for="item in listByDate.bmjzr" :key="item.id" @click="todetail(item.id)">{{
              item.title
            }}</div>
          </div>
        </div>
        <div class="jpr listitem">
          <div class="listtitle">
            <p>竞拍日</p>
          </div>
          <div class="listcon">
            <div v-if="listByDate.jpr.length === 0">
              当前日无数据
            </div>
            <div v-else class="conitem" v-for="item in listByDate.jpr" :key="item.id" @click="todetail(item.id)">{{
              item.title
            }}</div>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import { getDateList, getMarkList } from "@/api/index";
export default {
  name: "FrontendPcBigdataCalendar",

  data() {
    return {
      currentdate: null,
      markList: [],
      date: {},
      data: {},
      listByDate: {
        bmjzr: [],
        jpr: [],
      },
    };
  },

  computed: {
    site: {
      get() {
        return this.$store.state.site
      },
      set(v) {
        this.$store.dispatch('site', v);
      }
    }
  },

  watch: {
    currentdate(nv) {
      const ymd = this.getDateYMDString(nv);
      this.showList(ymd);
    },
    $route: {
      handler(v) {
        const ymd = this.getDateYMDString(nv);
        this.showList(ymd);
      },
      immediate: true,
      deep: true
    }


  },

  mounted() {

    // const ymd = this.getDateYMDString();
    const ymd = this.$route.params.day
    this.datelist(ymd);
    this.currentdate = ymd;
    const ym = ymd.slice(0, 7);
    this.marklist(ym);
  },

  methods: {

    todetail(id) {
      const tourl = '/' + this.site.path + '/detail/gonggao/' + id
      // this.$router.push(tourl)
      let { href } = this.$router.resolve({ path:tourl })
			window.open(href, '_blank')
    },

    changedata(day) {

    },

    getDateYMDString(string = null) {
      let d;
      if (string) {
        d = new Date(string);
      } else {
        d = new Date();
      }

      const y = d.getFullYear();
      const getm = d.getMonth();
      let day = d.getDate();

      let m = "";
      if (getm <  9) {
        m = "0" + (getm + 1);
      } else {
        m = getm + 1;
      }
      if (day < 10) {
        day = "0" + day;
      }

      return y + "-" + m + "-" + day;
    },

    toUrl() {
      this.$router.push("/index");
    },
    showList(riqi) {
      this.marklist(riqi);
      this.datelist(riqi);
    },

    marklist(riqi) {
      var that = this;
      const markParams = {
        month: riqi.slice(0, 7),
        site_id: this.site.id
      };
      getMarkList(markParams).then((res) => {
        that.markList = res.data;
      });
    },

    datelist(riqi) {

      var that = this;
      const params = {
        showdate: riqi,
        site_id: this.site.id
      };

      getDateList(params).then((res) => {
        that.listByDate = res.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dayarea {
  // background: #fff;
  display: flex;
  height: 80px;
  display: flex;
  flex-direction: column;

}

.bmjzr {
  .listtitle {
    p {
      font-size: 10px;
      color: #e88f09;
      border-bottom: solid 4px #e88f09;
    }
  }
}

.jpr {
  .listtitle {
    p {
      font-size: 10px;
      color: #00a3e4;
      border-bottom: solid 4px #00a3e4;
    }
  }
}

.listitem {
  padding-left: 10px;

  .listtitle {
    display: flex;

    p {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      line-height: 28px;
    }
  }

  .listcon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
     font-size: 14px;

    .conitem {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 40px;
      cursor: pointer;
    }
  }
}

.miaoshu {
  position: relative;
  top:-15px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  .orange,
  .blue {
    font-size:10px;
    margin-left: 20px;
    margin-right: 20px;
  }
}

.ccontiner {
  background: #f0f3f8;
}

.cconbtn {
  display: flex;
  justify-content: space-around;
}

.orange {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #e88f09;
}

.blue {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #00a3e4;
}

.callcon {
  display: flex;
  justify-content: space-between;

  .calendarleft {
    width:830px;
    // width: 71%;
    // margin: 2%;
    background: #fff;
    min-height: calc(100vh - 180px);

    .ccalender {
      min-height: calc(100vh - 240px);
      // background: red;
      overflow: scroll;
    }
  }

  .list {
    // width: 23%;
    width:356px;
    margin-left:14px;
    // margin: 2%;
    margin-left: 0;
    min-height: calc(100vh - 180px);
    background: #fff;
    overflow: hidden;
  }
}

.calender {
  display: flex;
  justify-content: space-between;
  margin-right: 30px;
  padding-top: 24px;

  .ctitle {
    margin-left: 30px;
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333640;
    line-height: 30px;
  }
}
</style>