import request from '@/utils/request'
import requestMock from '@/utils/requestMock'
//mock数据使用  requestMock需要换成request 删除此行

import loginRequest from '@/utils/loginRequest'
import Qs from 'qs'

//首页list
export function indexlist(params){
  return request({
    // url: '/mock/index.json',
    url: '/frontend/bigdata/economic/home-page',
    method: 'post',
    params
  })
}




//日历页面  获取选中日期列表  传入年月日 2022-06-19
export function getDateList(params){
  return loginRequest({
    url: '/frontend/website/notice/calendar-title',
    method: 'post',
    data:Qs.stringify(params)
  })
}




//日历页面  获取日期列表  传入年月   2022-07
export function getMarkList(params){
  return loginRequest({
    url: '/frontend/website/notice/calendar',
    method: 'post',
    data:Qs.stringify(params)
  })
}



// export function indexlist(params) {
//   return request({
//     url: '',
//     method: 'post',
//     params
//   })
// }
